import { Dialog, Classes, Button, Icon, Intent } from '@blueprintjs/core';
import { TradingPair } from '../../../shared/interfaces/bot';

import { TradingPairForm } from '../form/form';
import styles from './edit-dialog.module.css';
import React, { useContext, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { BotsManagerContext } from '../../../contexts/bots-manager';

interface Props {
  isVisible: boolean;
  setVisible: (visible: boolean) => void;
}

export function TradingPairCreateDialog({ isVisible, setVisible }: Props) {
  let navigate = useNavigate();
  const { currentBot } = useContext(BotsManagerContext);
  const [newTradingPair, setNewTradingPair] = useState<TradingPair | null>(null);
  const afterCreateTradingPair = ({ data }: any) => {
    setNewTradingPair(data);
  };

  const openNewTradingPairPage = () => {
    // Go back to trading pairs listing page
    setNewTradingPair(null);
    setVisible(false);
    navigate(`/bots/${currentBot?.app_id}/trading_pairs/recently_created`);
  };

  const handleClose = () => {
    if (!newTradingPair) {
      return setVisible(false);
    }

    const url = `/bots/${currentBot?.app_id}/trading_pairs/recently_created?pair_id=${newTradingPair.id}`;
    // Go back to trading pairs listing page
    setNewTradingPair(null);
    navigate(url);
    setVisible(false);
  };

  return (
    <Dialog
      portalContainer={document.getElementById('app') || undefined}
      title="New Trading Pair"
      transitionDuration={500}
      isOpen={isVisible}
      onClose={handleClose}
      style={{ minWidth: '800px' }}
      enforceFocus={false}
    >
      {newTradingPair ? (
        <div className={Classes.DIALOG_BODY}>
          <div>
            <span>
              The trading pair <strong>{newTradingPair.name}</strong> has been created. <Icon icon="endorsed" intent={Intent.SUCCESS} />
            </span>
            <div className="mt-7 text-right">
              <Button onClick={handleClose}>Close</Button>
              <Button intent="primary" className="ml-3" onClick={handleClose}>
                Open Trading Pair
              </Button>
            </div>
          </div>
        </div>
      ) : (
        <div className={Classes.DIALOG_BODY}>
          <TradingPairForm afterCreatePair={afterCreateTradingPair} />
        </div>
      )}
    </Dialog>
  );
}
