import { FormEvent, useCallback, useContext, useEffect, useRef, useState } from 'react';
import axios from '../../shared/custom-axios';
import { Link, useMatch, useSearchParams } from 'react-router-dom';
import { Alignment, HTMLSelect, Icon, Label, Navbar, Switch, Tab, Tabs } from '@blueprintjs/core';

import { PairsSortingOptions, TradingPair, TradingPairsSummary } from '../../shared/interfaces/bot';
import { BotsManagerContext } from '../../contexts/bots-manager';
import { usePageColumns as useLayoutColumns } from '../../components/common/use-layout-columns';
import { Pagination } from '../../components/common/pagination/pagination';

import { TradingPairsList } from '../../components/trading-pair/list/list';

interface TradingPairsListResponse {
  entries: TradingPair[];
  page_number: number;
  page_size: number;
  total_entries: number;
  total_pages: number;
}

const sortByName = (p1: TradingPair, p2: TradingPair) => {
  return p1.name > p2.name ? 1 : -1;
};

const sortByMostRecent = (p1: TradingPair, p2: TradingPair) => {
  return new Date(p1.last_active_at) > new Date(p2.last_active_at) ? -1 : 1;
};

const sortByPrimaryExchange = (p1: TradingPair, p2: TradingPair) => {
  const primary1 = `${p1.primary_instrument.main_exchange}_${p1.primary_instrument.sub_exchange}_${p1.primary_instrument.symbol}`;
  const primary2 = `${p2.primary_instrument.main_exchange}_${p2.primary_instrument.sub_exchange}_${p2.primary_instrument.symbol}`;
  return primary1 > primary2 ? 1 : -1;
};

const sortByPrimaryInstrument = (p1: TradingPair, p2: TradingPair) => {
  const primaryInstrument1 = `${p1.primary_instrument.symbol}_${p1.primary_instrument.main_exchange}_${p1.primary_instrument.sub_exchange}`;
  const primaryInstrument2 = `${p2.primary_instrument.symbol}_${p2.primary_instrument.main_exchange}_${p2.primary_instrument.sub_exchange}`;
  return primaryInstrument1 > primaryInstrument2 ? 1 : -1;
};

const sortBySecondaryExchange = (p1: TradingPair, p2: TradingPair) => {
  const s1 = `${p1.secondary_instrument.main_exchange}_${p1.secondary_instrument.sub_exchange}_${p1.secondary_instrument.symbol}`;
  const s2 = `${p2.secondary_instrument.main_exchange}_${p2.secondary_instrument.sub_exchange}_${p2.secondary_instrument.symbol}`;
  return s1 > s2 ? 1 : -1;
};

const sortBySecondaryInstrument = (p1: TradingPair, p2: TradingPair) => {
  const s1 = `${p1.secondary_instrument.symbol}_${p1.secondary_instrument.main_exchange}_${p1.secondary_instrument.sub_exchange}`;
  const s2 = `${p2.secondary_instrument.symbol}_${p2.secondary_instrument.main_exchange}_${p2.secondary_instrument.sub_exchange}`;
  return s1 > s2 ? 1 : -1;
};

export const TradingPairsIndexPage = (props: any) => {
  const SORT_OPTIONS = [
    { text: 'Most Recent Active', value: PairsSortingOptions.MostRecentActive },
    { text: 'Primary Exchange', value: PairsSortingOptions.PrimaryExchange },
    { text: 'Primary Instrument', value: PairsSortingOptions.PrimaryInstrument },
    { text: 'Secondary Exchange', value: PairsSortingOptions.SecondaryExchange },
    { text: 'Secondary Instrument', value: PairsSortingOptions.SecondaryInstrument },
    { text: 'A → Z', value: PairsSortingOptions.Alphabet },
  ];
  const [sortBy, setSortBy] = useState<PairsSortingOptions>(SORT_OPTIONS[0].value);

  const { currentBot } = useContext(BotsManagerContext);
  const [tradingPairs, setTradingPairs] = useState<TradingPair[]>([]);
  const [pairsSummary, setPairsSummary] = useState<TradingPairsSummary | null>();
  const match = useMatch(`/bots/${currentBot?.app_id}/trading_pairs/:subPage`);
  const subPage = match?.params.subPage || 'all';
  const [layoutColumns, setLayoutColumns] = useLayoutColumns('trading_pairs_page_columns');
  const supportedColumnOptions = [1, 2, 3, 4, 5];
  const [activePath, setActivePath] = useState('');
  const [recentlyActivePath, setRecentlyActivePath] = useState('');
  const [recentlyCreatedPath, setRecentlyCreatedPath] = useState('');
  const [allPath, setAllPath] = useState('');

  const [searchParams, setSearchParams] = useSearchParams();

  let defaultPageSize = searchParams.get('page_size') ? Number(searchParams.get('page_size')) : 50;
  let page = searchParams.get('page') ? Number(searchParams.get('page')) : 1;
  let pageIndex = page - 1;

  const [pageCount, setPageCount] = useState(0);
  const [pageSize, setPageSize] = useState(defaultPageSize);
  const [totalEntries, setTotalEntries] = useState(0);

  const handlePageChange = useCallback((pageIndex: number, pageSize: number) => {
    updateUrl(pageSize, pageIndex);
  }, []);

  const updateUrl = useCallback((pageSize: number, pageIndex: number) => {
    const page = pageIndex + 1;
    searchParams.set('page_size', String(pageSize));
    searchParams.set('page', String(page));

    // Set search params and navigate to new URL
    setSearchParams(searchParams);
  }, []);

  useEffect(() => {
    fetchData({ pageIndex, pageSize, subPage });
  }, [currentBot?.id, searchParams, pageIndex, pageSize, subPage]);

  useEffect(() => {
    if (!currentBot) return;

    let includeWickCatcherPair = searchParams.get('include_wick_catcher_pair') ? searchParams.get('include_wick_catcher_pair') : 'false';

    let active = `/bots/${currentBot.app_id}/trading_pairs/active`;
    let recentlyActive = `/bots/${currentBot.app_id}/trading_pairs/recently_active`;
    let recentlyCreated = `/bots/${currentBot.app_id}/trading_pairs/recently_created`;
    let all = `/bots/${currentBot.app_id}/trading_pairs`;

    if (includeWickCatcherPair === 'true') {
      setActivePath(`${active}?include_wick_catcher_pair=${includeWickCatcherPair}`);
      setRecentlyActivePath(`${recentlyActive}?include_wick_catcher_pair=${includeWickCatcherPair}`);
      setRecentlyCreatedPath(`${recentlyCreated}?include_wick_catcher_pair=${includeWickCatcherPair}`);
      setAllPath(`${all}?include_wick_catcher_pair=${includeWickCatcherPair}`);
    } else {
      setActivePath(active);
      setRecentlyActivePath(recentlyActive);
      setRecentlyCreatedPath(recentlyCreated);
      setAllPath(all);
    }
  }, [currentBot, searchParams]);

  useEffect(() => {
    if (!currentBot) {
      return;
    }

    let includeWickCatcherPair = searchParams.get('include_wick_catcher_pair') ? searchParams.get('include_wick_catcher_pair') : 'false';
    let path = `/api/trading_pairs/summary?bot_id=${currentBot?.id}&include_wick_catcher_pair=${includeWickCatcherPair}`;

    axios.get<any>(path).then((response) => {
      setPairsSummary(response.data);
    });
  }, [currentBot, subPage, searchParams]);

  const updateTradingPairList = useCallback((tradingPairs: TradingPair[]) => {
    setTradingPairs(sortActivePairList(tradingPairs, sortBy));
  }, []);

  const fetchData = useCallback(
    ({ pageSize, pageIndex }: any) => {
      let includeWickCatcherPair = searchParams.get('include_wick_catcher_pair') ? searchParams.get('include_wick_catcher_pair') : 'false';

      let path;

      switch (subPage) {
        case 'active': {
          path = `/api/trading_pairs?bot_id=${currentBot?.id}&status=active&page_size=${pageSize}&page=${
            pageIndex + 1
          }&include_wick_catcher_pair=${includeWickCatcherPair}`;

          break;
        }

        case 'recently_active': {
          path = `/api/trading_pairs/recently_active?bot_id=${currentBot?.id}&page_size=${pageSize}&page=${
            pageIndex + 1
          }&include_wick_catcher_pair=${includeWickCatcherPair}`;

          break;
        }

        case 'recently_created': {
          path = `/api/trading_pairs/recently_created?bot_id=${currentBot?.id}&page_size=${pageSize}&page=${
            pageIndex + 1
          }&include_wick_catcher_pair=${includeWickCatcherPair}`;

          break;
        }

        default: {
          path = `/api/trading_pairs?bot_id=${currentBot?.id}&page_size=${pageSize}&page=${
            pageIndex + 1
          }&include_wick_catcher_pair=${includeWickCatcherPair}`;

          break;
        }
      }

      axios.get<TradingPairsListResponse>(path).then((response) => {
        const { entries, total_entries, total_pages } = response.data;
        setPageCount(total_pages);
        setTotalEntries(total_entries);

        updateTradingPairList(entries);
      });
    },
    [currentBot?.id, subPage, searchParams, updateTradingPairList],
  );

  const handleSort = (event: FormEvent<HTMLInputElement | HTMLSelectElement>) => {
    const { value } = event.currentTarget;
    const sortingOption = value as PairsSortingOptions;
    setSortBy(sortingOption);
    setTradingPairs(sortActivePairList(tradingPairs, sortingOption));
  };

  const sortActivePairList = (tradingPairs: TradingPair[], sortBy: PairsSortingOptions) => {
    switch (sortBy) {
      case PairsSortingOptions.MostRecentActive:
        return tradingPairs.sort(sortByMostRecent);
      case PairsSortingOptions.Alphabet:
        return tradingPairs.sort(sortByName);
      case PairsSortingOptions.PrimaryExchange:
        return tradingPairs.sort(sortByPrimaryExchange);
      case PairsSortingOptions.PrimaryInstrument:
        return tradingPairs.sort(sortByPrimaryInstrument);
      case PairsSortingOptions.SecondaryExchange:
        return tradingPairs.sort(sortBySecondaryExchange);
      case PairsSortingOptions.SecondaryInstrument:
        return tradingPairs.sort(sortBySecondaryInstrument);
      default:
        return tradingPairs;
    }
  };

  if (!currentBot) {
    return <p>Current bot does not exist</p>;
  }

  return (
    <div className="">
      <div className="fixed top-16 left-56 mt-0.5 ml-4 right-0 mr-4 z-10">
        <Navbar>
          <Navbar.Group>
            <Navbar.Heading className="font-bold">Trading Pairs</Navbar.Heading>
          </Navbar.Group>

          <Navbar.Group>
            <Tabs animate={true} fill={true} selectedTabId={subPage} large={true} className="">
              <Tab
                id="active"
                title={
                  <Link to={activePath}>
                    {pairsSummary && pairsSummary.active ? (
                      <span>
                        Active <span className="text-gray-400">{pairsSummary.active.total_entries}</span>
                      </span>
                    ) : (
                      <span>
                        Active <span className="text-gray-400">-</span>
                      </span>
                    )}
                  </Link>
                }
              />

              <Tab
                id="recently_active"
                title={
                  <Link to={recentlyActivePath}>
                    {pairsSummary && pairsSummary.recently_active ? (
                      <span>
                        Recently Active <span className="text-gray-400">{pairsSummary.recently_active.total_entries}</span>
                      </span>
                    ) : (
                      <span>
                        Recently Active <span className="text-gray-400">0</span>
                      </span>
                    )}
                  </Link>
                }
              />

              <Tab
                id="recently_created"
                title={
                  <Link to={recentlyCreatedPath}>
                    {pairsSummary && pairsSummary.recently_created ? (
                      <span>
                        New <span className="text-gray-400">{pairsSummary.recently_created.total_entries}</span>
                      </span>
                    ) : (
                      <span>
                        New <span className="text-gray-400">0</span>
                      </span>
                    )}
                  </Link>
                }
              />

              <Tab
                id="all"
                title={
                  <Link to={allPath}>
                    {pairsSummary && pairsSummary.all ? (
                      <span>
                        All <span className="text-gray-400">{pairsSummary.all.total_entries}</span>
                      </span>
                    ) : (
                      <span>
                        All <span className="text-gray-400">-</span>
                      </span>
                    )}
                  </Link>
                }
              />
            </Tabs>
          </Navbar.Group>

          <Navbar.Group>
            <span className="ml-8 mb-0">
              <Switch
                checked={searchParams.get('include_wick_catcher_pair') === 'true'}
                labelElement={<strong>Show Wick Catcher pairs</strong>}
                onChange={() => {
                  let newValue = searchParams.get('include_wick_catcher_pair') === 'true' ? 'false' : 'true';
                  searchParams.set('include_wick_catcher_pair', newValue);
                  setSearchParams(searchParams);
                }}
                className="mt-3 mb-0"
              />
            </span>
          </Navbar.Group>

          <Navbar.Group align={Alignment.RIGHT}>
            <Icon icon="grid-view"></Icon>

            <HTMLSelect
              className="ml-3"
              name="exchange_type"
              value={layoutColumns}
              onChange={(e) => setLayoutColumns(+e.currentTarget.value)}
            >
              {supportedColumnOptions.map((columns) => {
                if (columns === 1) {
                  return (
                    <option value={columns} key={columns}>
                      1 column
                    </option>
                  );
                }

                return (
                  <option value={columns} key={columns}>
                    {columns} columns
                  </option>
                );
              })}
            </HTMLSelect>
          </Navbar.Group>
        </Navbar>
      </div>

      <div className="mt-14 flex justify-between">
        <div></div>

        <div className="flex items-center">
          <div className="">
            <Pagination
              isLoading={false}
              totalEntries={totalEntries}
              pageCount={pageCount}
              canPreviousPage={pageIndex - 1 >= 0}
              canNextPage={pageIndex + 1 < pageCount}
              pageOptions={[]}
              pageLength={pageCount}
              pageIndex={pageIndex}
              pageSize={pageSize}
              setPageSize={setPageSize}
              gotoPage={(page: number) => {
                handlePageChange(page, pageSize);
              }}
              nextPage={() => {
                handlePageChange(pageIndex + 1, pageSize);
              }}
              previousPage={() => {
                handlePageChange(pageIndex - 1, pageSize);
              }}
            />
          </div>

          <div className="ml-5">
            <Label className="bp4-label bp4-inline text-xs font-bold" style={{ marginBottom: '0' }}>
              Sort:
              <HTMLSelect name="sort_active_pairs" className="active-pair-sort-by" value={sortBy} onChange={handleSort}>
                {SORT_OPTIONS.map((opt) => (
                  <option value={opt.value} key={`sort_by_${opt.value}`}>
                    {opt.text}
                  </option>
                ))}
              </HTMLSelect>
            </Label>
          </div>
        </div>

        <div></div>
      </div>

      <div className="mt-2 flex flex-row clear-both">
        <TradingPairsList items={tradingPairs} setItems={setTradingPairs} layoutColumns={layoutColumns} />
      </div>
    </div>
  );
};
