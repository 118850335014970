import React, { useState, useContext, useRef, useEffect } from 'react';
import _ from 'lodash';
import { Button, Classes, Dialog, H5, Icon, Intent, PopoverInteractionKind, PopoverPosition, Tag } from '@blueprintjs/core';

import { ActiveOrder, ExchangeType, Opportunity, OpportunitySession, StrategyType, TradingPair } from '../../../shared/interfaces/bot';
import { ActiveOrderContext } from '../../../contexts/active-order';
import { OpportunitySessionContext } from '../../../contexts/opportunity-session';

import { OpportunitiesListTable } from './table';
import { OpportunitiesEditor } from '../editor/editor';
import { Popover2, Tooltip2, Classes as Tooltip2Classes } from '@blueprintjs/popover2';
import orderService from '../../../shared/order-service';
import { DetailsPopover } from '../active-order/details-popover';
import { TimezoneContext } from '../../../contexts/timezone';

const COLUMNS_FOR_NORMAL_TRADING = [
  'target_mode',
  'quantity',
  'active_order',
  'offset',
  'max_individual',
  'ec_offset_ec',
  'quota',
  'remain',
  'exit',
];

const COLUMNS_FOR_PAIRS_TRADING = [
  'target_mode',
  'quantity',
  'active_order',
  'offset',
  'max_individual',
  'ec_offset_ec',
  'quota',
  'remain',
  'exit',
];

const COLUMNS_FOR_WICK_CATCHER_TRADING = ['target_mode', 'quantity', 'active_order', 'offset', 'max_individual', 'quota', 'remain'];

const COLUMNS_FOR_WICK_CATCH_ARB_TRADING = [
  'target_mode',
  'quantity',
  'active_order',
  'distance',
  'offset',
  'max_individual',
  'quota',
  'remain',
];

const COLUMNS_FOR_DEFI = ['target_mode', 'quantity', 'active_order', 'offset', 'defi_slippage', 'quota', 'remain'];
const MAX_CONDITION_MET = 10;

interface Props {
  tradingPair: TradingPair;
  onUpdate: (tradingPairId: number, payload: any) => void;
  onReloadPair: (tradingPairId: number) => Promise<any>;
  minimal?: boolean;
  viewedOpportunity?: number;
}

function getRemain(opportunity: Opportunity, opportunitySession: OpportunitySession) {
  const { quota } = opportunity;

  if (!_.isEmpty(opportunitySession)) {
    // Replenish = 0 means the opportunity just initiated and about to start
    // When it starts, replenish would be 1 and count up by 1 after every fill
    const fillsCount = opportunitySession.replenish - 1;

    if (fillsCount < 0) {
      return quota;
    }

    return quota - fillsCount;
  }
}

const OpportunityVolumeWarning = ({ isSkipped, message }: any) =>
  isSkipped ? (
    <Tooltip2 content={message} placement="top">
      <Icon icon="warning-sign" intent={Intent.WARNING} />
    </Tooltip2>
  ) : null;

const OpportunityExceedRateLimitWarning = ({ error }: any) =>
  error ? (
    <Tooltip2 content={error.error} placement="top">
      <Icon size={11} icon="warning-sign" intent={Intent.WARNING} className="mr-1 pb-0.5" />
    </Tooltip2>
  ) : null;

export function OpportunitiesList({ tradingPair, onUpdate, onReloadPair, minimal = false, viewedOpportunity }: Props) {
  const { dateTimeFormater } = useContext(TimezoneContext);
  const { currency } = tradingPair;
  const { activeOrders } = useContext(ActiveOrderContext);
  const { opportunitySessions } = useContext(OpportunitySessionContext);
  const [activeOrdersByOpportunity, setActiveOrdersByOpportunity] = useState<{ [key: string]: Array<ActiveOrder> }>({});
  const isDeFiTrading =
    tradingPair.primary_instrument.exchange_type === ExchangeType.DEX ||
    tradingPair.secondary_instrument.exchange_type === ExchangeType.DEX;
  const isPairsTrading = tradingPair.strategy_type === StrategyType.PairsTrading;
  const isWickCatcherTrading = tradingPair.strategy_type === StrategyType.WickCatcher;
  const isWickCatchArbTrading = tradingPair.strategy_type === StrategyType.WickCatchArb;

  const getOpportunityVolumeIssue = (tradingPairId: number, opportunityId: number) => {
    let errors = [];
    if (orderService.isBigVolumeOpportunity(opportunityId)) {
      errors.push('The volume is exceeded the primary order threshold');
    }

    if (orderService.isSkippedHedging(tradingPairId, opportunityId)) {
      errors.push('Skipped hedging because the hedge volume is exceeded the threshold.');
    }

    return {
      isSkipped: errors.length > 0,
      message:
        errors.length > 1 ? (
          <span>
            {errors[0]} <br /> {errors[1]}
          </span>
        ) : (
          <span>{errors[0]}</span>
        ),
    };
  };

  useEffect(() => {
    const pairActiveOrders = activeOrders.filter((order) => order.trading_pair.id === tradingPair.id);

    const mappings = pairActiveOrders.reduce((acc: { [key: string]: Array<ActiveOrder> }, order: ActiveOrder) => {
      const currentOrders = acc[order.opportunity.id];

      if (currentOrders && currentOrders.length > 0) {
        acc[order.opportunity.id] = [...currentOrders, order];
      } else {
        acc[order.opportunity.id] = [order];
      }

      return acc;
    }, {});

    setActiveOrdersByOpportunity(mappings);
  }, [tradingPair, activeOrders]);

  useEffect(() => {
    const changedQuotaOpp = tradingPair.opportunities.filter((opportunity: Opportunity) => {
      const opportunitySession = opportunitySessions?.[opportunity.id];

      return opportunitySession && opportunitySession.quota > 0 && opportunitySession.quota !== opportunity.quota;
    });
    if (changedQuotaOpp.length > 0) {
      onReloadPair(tradingPair.id);
    }
  }, [opportunitySessions]);

  const columns = React.useMemo(() => {
    const columns = [
      {
        id: 'target_mode',
        Header: (
          <>
            {isPairsTrading && <span className="block">Ratio</span>}
            {!isPairsTrading && <span className="block">Target (%)</span>}
            {!isDeFiTrading && <span className="block">Mode</span>}
            {!isDeFiTrading && <span className="block">Debounce</span>}
          </>
        ),
        accessor: (opportunity: Opportunity, _rowIndex: number) => {
          const { side, debounce } = opportunity;

          if (side === 'short' || side === 'short_stop_loss') {
            return (
              <span className="text-center">
                <Tag minimal={true} fill={true} intent={Intent.DANGER}>
                  {isPairsTrading && opportunity.ratio}
                  {!isPairsTrading && opportunity.percentage}
                  {opportunity.is_flip ? <span className="ml-1 font-semibold">Flip</span> : null}
                </Tag>

                {opportunity.mode && !isDeFiTrading && (
                  <Tag minimal={true} fill={true} intent={Intent.NONE}>
                    <span className="block">
                      {_.capitalize(opportunity.mode[0])}
                      {debounce ? debounce / 1000 : '-'}s
                    </span>
                  </Tag>
                )}
              </span>
            );
          }

          if (side === 'long' || side === 'long_stop_loss') {
            return (
              <span className="text-center">
                <Tag minimal={true} fill={true} intent={Intent.SUCCESS} className="text-center">
                  {isPairsTrading && <span style={opportunity.is_flip ? { color: '#69e189' } : {}}>{opportunity.ratio}</span>}
                  {!isPairsTrading && <span style={opportunity.is_flip ? { color: '#69e189' } : {}}>{opportunity.percentage}</span>}
                  {opportunity.is_flip ? <span className="ml-1 font-semibold text-white">Flip</span> : null}
                </Tag>

                {opportunity.mode && !isDeFiTrading && (
                  <Tag minimal={true} fill={true} intent={Intent.NONE}>
                    <span className={opportunity.is_flip ? 'text-white block font-semibold' : 'block'}>
                      {_.capitalize(opportunity.mode[0])}
                      {debounce ? debounce / 1000 : '-'}s
                    </span>
                  </Tag>
                )}
              </span>
            );
          }
        },
        width: 72,
      },
      {
        id: 'quantity',
        Header: (
          <>
            <span className="block">Qty</span>
            <span className="block font-normal">({currency})</span>
          </>
        ),
        accessor: (opportunity: Opportunity, _rowIndex: number) => {
          const { isSkipped, message } = getOpportunityVolumeIssue(opportunity.trading_pair_id, opportunity.id);
          return (
            <>
              <OpportunityVolumeWarning isSkipped={isSkipped} message={message} />
              <span className="ml-1">{opportunity.quantity.toLocaleString('en-US', { maximumFractionDigits: 10 })}</span>
            </>
          );
        },
        width: 64,
      },
      {
        id: 'active_order',
        Header: 'Active Order',
        accessor: (opportunity: Opportunity, _rowIndex: number) => {
          const activeOrders = activeOrdersByOpportunity[opportunity.id] || [];
          const opportunitySession = opportunitySessions?.[opportunity.id];
          const remain = getRemain(opportunity, opportunitySession);
          const isShort = opportunity.side === 'short' && tradingPair.short_mode === 'open_short';
          const isLong = opportunity.side === 'long' && tradingPair.long_mode === 'open_long';
          const isShortStopLoss = opportunity.side === 'short_stop_loss' && tradingPair.short_mode === 'open_short';
          const isLongStopLoss = opportunity.side === 'long_stop_loss' && tradingPair.long_mode === 'open_long';
          const isRunning = isShort || isLong || isShortStopLoss || isLongStopLoss;
          const isOrderMissing = isRunning && _.isNumber(remain) && remain > 0 && activeOrders.length === 0;
          const isBuyOrder = (isLong && !opportunity.is_flip) || (isShort && opportunity.is_flip);
          const isSellOrder = (isLong && opportunity.is_flip) || (isShort && !opportunity.is_flip);

          // If opportunity is stop loss, show the condition met counter
          if (isShortStopLoss || isLongStopLoss) {
            return (
              <>
                <span className="block text-left font-semibold">
                  Conditions met count:
                  <span className="ml-2 font-bold text-blue-500">{opportunitySession?.stop_loss_condition_counter}</span>
                  {opportunitySession?.stop_loss_condition_counter > MAX_CONDITION_MET ? (
                    <Tag minimal={true} intent={Intent.WARNING} className="text-center">
                      Not enough position
                    </Tag>
                  ) : null}
                </span>
              </>
            );
          }

          // If opportunity is not fulfilled and there is no active order, show the missing order warning
          if (isOrderMissing) {
            const rateLimitError = orderService.getOrderRateLimitError(opportunity.id);
            return (
              <>
                <span className="block text-center" onClick={(e) => e.stopPropagation()} onDoubleClick={(e) => e.stopPropagation()}>
                  <Popover2
                    popoverClassName={Classes.POPOVER_CONTENT_SIZING}
                    enforceFocus={false}
                    minimal={false}
                    position={PopoverPosition.TOP}
                    interactionKind={PopoverInteractionKind.CLICK}
                    content={
                      opportunitySession.last_skip && (
                        <div key="text" className="whitespace-nowrap p-5">
                          <H5>Skipped putting order</H5>

                          <div className="">
                            <p className="">Reason:</p>
                            <p className="font-bold">{opportunitySession.last_skip.reason}</p>
                          </div>

                          <div className="mt-2">
                            <p>Skipped at:</p>{' '}
                            <p className="font-bold">{dateTimeFormater.format(new Date(opportunitySession.last_skip.timestamp + 'Z'))}</p>
                          </div>
                        </div>
                      )
                    }
                  >
                    <Tag minimal={true} intent={Intent.WARNING} className="text-center">
                      <OpportunityExceedRateLimitWarning error={rateLimitError} />

                      <Tooltip2
                        hoverOpenDelay={0}
                        content={'Click to see the reason'}
                        placement="top"
                        className={Tooltip2Classes.TOOLTIP2_INDICATOR}
                      >
                        Missing order
                      </Tooltip2>
                    </Tag>
                  </Popover2>
                </span>
              </>
            );
          }

          if (activeOrders.length < 1) {
            return;
          }

          const { total_value, original, local_order_book } = activeOrders[0];
          const currentActiveValue = total_value.amount;
          const originalValue = original.total_value.amount;
          const currentActivePrice = original.price.amount;
          const diffsComparedToBestPrice = (
            ((currentActivePrice - local_order_book.best_price) / local_order_book.best_price) *
            100
          ).toFixed(3);

          if (activeOrders.length === 1) {
            return (
              <DetailsPopover activeOrders={activeOrders}>
                <>
                  <span className={`block ${isBuyOrder ? 'long-order' : isSellOrder ? 'short-order' : ''}`}>
                    {currentActiveValue} / {originalValue} {total_value.currency}
                  </span>
                  <span className={`block ${isBuyOrder ? 'long-order' : isSellOrder ? 'short-order' : ''}`}>
                    {currentActivePrice} {original.price.currency}
                  </span>
                  <span className={`block ${isBuyOrder ? 'long-order' : isSellOrder ? 'short-order' : ''}`}>
                    ({diffsComparedToBestPrice}%)
                  </span>
                </>
              </DetailsPopover>
            );
          }

          if (activeOrders.length > 1) {
            return (
              <DetailsPopover activeOrders={activeOrders}>
                <>
                  <span className={`block ${isBuyOrder ? 'long-order' : isSellOrder ? 'short-order' : ''}`}>
                    {currentActiveValue} / {originalValue} {total_value.currency}
                  </span>
                  <span className={`block ${isBuyOrder ? 'long-order' : isSellOrder ? 'short-order' : ''}`}>
                    {currentActivePrice} {original.price.currency}
                  </span>
                  <span className={`block ${isBuyOrder ? 'long-order' : isSellOrder ? 'short-order' : ''}`}>
                    ({diffsComparedToBestPrice}%)
                  </span>
                  <span className="block mt-2 text-xs">
                    <Tag minimal={true} interactive={true} intent="warning" round={true} rightIcon="warning-sign">
                      +{activeOrders.length - 1} more orders
                    </Tag>
                  </span>
                </>
              </DetailsPopover>
            );
          }
        },
        width: 128,
      },
      {
        id: 'distance',
        Header: (
          <>
            <span className="block">Distance</span>
            <span className="block font-normal">({currency})</span>
          </>
        ),
        accessor: (opportunity: Opportunity, _rowIndex: number) => {
          const { distance } = opportunity;

          return (
            <>
              <span className="block">{distance ? distance.toLocaleString('en-US', { maximumFractionDigits: 10 }) : '-'}</span>
            </>
          );
        },
        width: 68,
      },
      {
        id: 'offset',
        Header: (
          <>
            <span className="block">Offset</span>
            <span className="block font-normal">({currency})</span>
          </>
        ),
        accessor: (opportunity: Opportunity, _rowIndex: number) => {
          const { offset } = opportunity;

          return (
            <>
              <span className="block">{offset ? offset.toLocaleString('en-US', { maximumFractionDigits: 10 }) : '-'}</span>
            </>
          );
        },
        width: 68,
      },
      {
        id: 'max_individual',
        Header: (
          <>
            <span className="block">Max Indiv</span>
            <span className="block font-normal">({currency})</span>
          </>
        ),
        accessor: (opportunity: Opportunity, _rowIndex: number) => {
          const { max_individual } = opportunity;

          return (
            <>
              <span className="block">{max_individual ? max_individual.toLocaleString('en-US', { maximumFractionDigits: 10 }) : '-'}</span>
            </>
          );
        },
        width: 68,
      },
      {
        id: 'ec_offset_ec',
        Header: (
          <>
            <span className="block">EC</span>
            <span className="block">Offset EC</span>
            <span className="block font-normal">(%)</span>
          </>
        ),
        accessor: (opportunity: Opportunity, _rowIndex: number) => {
          const { emergency_cancel, offset_ec } = opportunity;

          return (
            <>
              <span className="block">{emergency_cancel ? emergency_cancel : '-'}</span>
              <span className="block">{offset_ec ? offset_ec : '-'}</span>
            </>
          );
        },
        width: 68,
      },
      {
        id: 'defi_slippage',
        Header: (
          <>
            <span className="block">Slippage</span>
            <span className="block font-normal">(%)</span>
          </>
        ),
        accessor: (opportunity: Opportunity, _rowIndex: number) => {
          const { defi_slippage } = opportunity;

          return (
            <>
              <span className="block">{defi_slippage ? defi_slippage : '-'}</span>
            </>
          );
        },
        width: 68,
      },
      {
        id: 'quota',
        Header: (
          <>
            <span className="block">Quota</span>
          </>
        ),
        accessor: (opportunity: Opportunity, _rowIndex: number) => {
          const { quota } = opportunity;

          return (
            <>
              <span className="block">{_.isNumber(quota) ? parseFloat(quota.toFixed(2)) : '-'}</span>
            </>
          );
        },
        width: 50,
      },
      {
        id: 'remain',
        Header: (
          <>
            <span className="block">Remain</span>
          </>
        ),
        accessor: (opportunity: Opportunity, _rowIndex: number) => {
          let remain;
          const opportunitySession = opportunitySessions?.[opportunity.id];

          if (opportunitySession) {
            remain = getRemain(opportunity, opportunitySession);
          }

          if (_.isNumber(remain) && !isNaN(remain)) {
            if (Math.ceil(remain) === 0) {
              return (
                <span className="block text-center text-green-500">
                  <Icon icon="small-tick" />
                </span>
              );
            }

            if (remain < 0) {
              return <span className="block text-center text-red-500 font-semibold">{remain}</span>;
            }

            return <span className="block text-center">{remain.toFixed(2)}</span>;
          } else {
            return <span className="block text-center">-</span>;
          }
        },
        width: 50,
      },
      {
        id: 'exit',
        Header: (
          <>
            <span className="block">Exit?</span>
          </>
        ),
        accessor: (opportunity: Opportunity, _rowIndex: number) => {
          const { exit } = opportunity;

          return (
            <>
              <span className="block">{exit}</span>
            </>
          );
        },
        width: 40,
      },
    ];

    return columns.filter(({ id }) => {
      if (isDeFiTrading) {
        return COLUMNS_FOR_DEFI.includes(id);
      }

      if (isPairsTrading) {
        return COLUMNS_FOR_PAIRS_TRADING.includes(id);
      }

      if (isWickCatcherTrading) {
        return COLUMNS_FOR_WICK_CATCHER_TRADING.includes(id);
      }

      if (isWickCatchArbTrading) {
        return COLUMNS_FOR_WICK_CATCH_ARB_TRADING.includes(id);
      }

      return COLUMNS_FOR_NORMAL_TRADING.includes(id);
    });
  }, [currency, activeOrdersByOpportunity, opportunitySessions, isPairsTrading, isDeFiTrading]);

  const [viewMode, setViewMode] = useState<'normal' | 'edit'>('normal');
  const [sortedOpportunities, setSortedItems] = useState<Opportunity[]>([]);
  const editorRef = useRef<any>();

  useEffect(() => {
    // Sort opportunities by side, percentage and id
    let opportunities = _.orderBy(tradingPair.opportunities, ['side', 'percentage', 'id'], ['desc', 'desc', 'desc']);
    if (tradingPair.strategy_type === 'pairs_trading') {
      opportunities = _.orderBy(
        tradingPair.opportunities.map((opp: Opportunity) => {
          // ui_side is the actual side on opportunities list that the opportunity is on
          // "long_stop_loss" is actual a short order but we still place it on the long side,
          // along with other normal long opportunities, this is trader's expectation
          //
          // and vice versa for "short_stop_loss"
          // const actual_ui_side = opp.side === 'long_stop_loss' ? 'long' : opp.side;
          let actual_ui_side = opp.side;
          switch (opp.side) {
            case 'long_stop_loss': {
              actual_ui_side = 'long';
              break;
            }

            case 'short_stop_loss': {
              actual_ui_side = 'short';
              break;
            }
          }

          return Object.assign({}, opp, { actual_side: actual_ui_side });
        }),
        ['actual_side', 'ratio', 'id'],
        ['desc', 'desc', 'desc'],
      );
    }

    setSortedItems(opportunities);
  }, [tradingPair]);

  return (
    <>
      <Dialog
        title="Edit opportunity settings"
        icon="inbox"
        isOpen={viewMode === 'edit'}
        onClose={() => {
          setViewMode('normal');
        }}
        style={{ minWidth: '1250px' }}
      >
        <div className={Classes.DIALOG_BODY}>
          <OpportunitiesEditor tradingPair={tradingPair} items={sortedOpportunities} onUpdate={onUpdate} ref={editorRef} />
        </div>

        <div className={Classes.DIALOG_FOOTER}>
          <div className={Classes.DIALOG_FOOTER_ACTIONS}>
            <Button
              onClick={() => {
                setViewMode('normal');
              }}
            >
              Close
            </Button>

            <Button
              onClick={() => {
                editorRef.current?.handleTableSubmit(tradingPair.id);
              }}
              intent="primary"
              icon="floppy-disk"
            >
              Save & Reload
            </Button>
          </div>
        </div>
      </Dialog>

      {!minimal && (
        <div className="my-1">
          <Button text={`Edit (${sortedOpportunities.length})`} onClick={() => setViewMode('edit')} intent={Intent.NONE} icon="edit" />
        </div>
      )}

      <div
        onDoubleClick={(e) => {
          e.stopPropagation();
          setViewMode('edit');
        }}
        className="overflow-auto"
        style={{ height: '345px' }}
      >
        <OpportunitiesListTable columns={columns} data={sortedOpportunities} viewedOpportunity={viewedOpportunity} />
      </div>
    </>
  );
}
