import { useCallback, useEffect, useState } from 'react';
import axios from '../../shared/custom-axios';
import { useNavigate, useParams } from 'react-router-dom';
import { Intent } from '@blueprintjs/core';

import { Instrument, InstrumentResponse } from '../../shared/interfaces/bot';
import { AppToaster } from '../../shared/app-toaster';

import { InstrumentForm } from '../../components/instrument/form/form';

export const InstrumentEditPage = (props: any) => {
  let navigate = useNavigate();
  let { id } = useParams<{ id: string }>();

  const [item, setItem] = useState<Instrument | null>(null);

  useEffect(() => {
    axios
      .get<InstrumentResponse>(`/api/instruments/${id}`)
      .then((response) => {
        const newData = response.data.data;
        setItem(newData);
      })
      .catch((error) => {
        const message = JSON.stringify(error.response.data);
        AppToaster.show({ message: message, icon: 'warning-sign', intent: Intent.DANGER, timeout: 3500 });
      });
  }, [id]);

  const handleCreate = useCallback((formState: object) => {
    axios
      .post<Instrument[]>('/api/instruments', { instrument: formState })
      .then(() => {
        navigate('/instruments');
        const message = 'Instrument created successfully!';
        AppToaster.show({ message: message, icon: 'tick', intent: Intent.SUCCESS, timeout: 2000 });
      })
      .catch((error) => {
        const message = JSON.stringify(error.response.data);
        AppToaster.show({ message: message, icon: 'warning-sign', intent: Intent.DANGER, timeout: 3500 });
      });
  }, []);

  const handleUpdate = useCallback((instrumentId: number, formState: object) => {
    axios
      .put<InstrumentResponse>(`/api/instruments/${instrumentId}`, { instrument: formState })
      .then((response) => {
        const message = 'Instrument updated';
        AppToaster.show({ message: message, icon: 'tick', intent: Intent.SUCCESS, timeout: 2000 });
      })
      .catch((error) => {
        const error_message = error.response.data.error_message;
        AppToaster.show({ message: error_message, icon: 'warning-sign', intent: Intent.DANGER, timeout: 3500 });
      });
  }, []);

  return (
    <>
      <h2 className="text-lg font-bold my-1">Edit instrument</h2>

      <InstrumentForm item={item} onCreate={handleCreate} onUpdate={handleUpdate} />
    </>
  );
};
